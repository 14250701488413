import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum ConstructionTooltipEnum {
  edili = 'edili',
  miste = 'miste',
  notEdili = 'notEdili',
  selfEmployed = 'selfEmployed',
  notRatedYet = 'notRatedYet',
}

@Component({
  selector: 'app-construction-tooltip-dialog',
  templateUrl: './construction-tooltip-dialog.component.html',
  styleUrls: ['./construction-tooltip-dialog.component.scss']
})
export class ConstructionTooltipDialogComponent implements OnInit {

  readonly ConstructionTooltipEnum = ConstructionTooltipEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {type: ConstructionTooltipEnum}) { }

  ngOnInit(): void {
  }

}
