<div class="dialog-header">
  <div class="dialog-title">
    {{data.type === ConstructionTooltipEnum.edili ? 'Imprese edili' : (
      data.type === ConstructionTooltipEnum.miste ? 'Imprese miste' : (
      data.type === ConstructionTooltipEnum.notEdili ? 'Imprese non edili' : 'Lavoratori autonomi / Imprese senza dipendenti'
    )
  )}}
  </div>
  <img src="/assets/img/icons/xmark.svg" class="btn-close-img" [mat-dialog-close]="null"/>
</div>

<mat-dialog-content>
  <ng-container *ngIf="data.type === ConstructionTooltipEnum.edili">
    Imprese caratterizzate da Codice ATECO proprio dell’edilizia, codice INPS CSC edile ed iscrizione alla Cassa Edile competente per territorio.
    <br>
    Le attività edili e di ingegneria civile sono elencate all’All. X del D.Lgs. 81/08:
    <br>
    ELENCO DEI LAVORI EDILI O DI INGEGNERIA CIVILE di cui all'articolo 89 comma 1, lettera a):
    <br><br><i>
    1. I lavori di costruzione, manutenzione, riparazione, demolizione, conservazione, risanamento, ristrutturazione o equipaggiamento, la trasformazione, il rinnovamento o lo smantellamento di opere fisse, permanenti o temporanee, in muratura, in cemento armato, in metallo, in legno o in altri materiali, comprese le parti strutturali delle linee elettriche e le parti strutturali degli impianti elettrici, le opere stradali, ferroviarie, idrauliche, marittime, idroelettriche e, solo per la parte che comporta lavori edili o di ingegneria civile, le opere di bonifica, di sistemazione forestale e di sterro.
    <br><br>
    2. Sono, inoltre, lavori di costruzione edile o di ingegneria civile gli scavi, ed il montaggio e lo smontaggio di elementi prefabbricati utilizzati per la realizzazione di lavori edili o di ingegneria civile.
  </i>
  </ng-container>

  <ng-container *ngIf="data.type === ConstructionTooltipEnum.miste">
    Imprese caratterizzate da più codici ATECO, tra i quali almeno uno edile, e più codici INPS CSC.
    <br><br>
    Non tutti i dipendenti dell’impresa sono legittimati allo svolgimento di attività “edili”: solo quei lavoratori inquadrati in ambito previdenziale ed assicurativo come edili (con posizione INPS, INAIL ed iscrizione in Cassa Edile adeguati), possono essere adibiti allo svolgimento delle attività inquadrate o inquadrabili come “edili” (cfr. All. X D.Lgs. 81/08)
  </ng-container>

  <ng-container *ngIf="data.type === ConstructionTooltipEnum.notEdili">
    Imprese con Codici Ateco e Codici INPS CSC diversi da quelli del settore edile. I lavoratori di queste aziende non sono legittimati a svolgere attività edili (cfr. All. X D.Lgs. 81/08).
    <br><br>
    In cantiere, ad esempio, possono occuparsi di impiantistica, fornitura materiali, finiture del verde.
  </ng-container>

  <ng-container *ngIf="data.type === ConstructionTooltipEnum.selfEmployed">
    I Lavoratori autonomi (art. 89 D.Lgs. 81/08) devono essere caratterizzati da autonomia nello svolgimento delle loro lavorazioni, e dall’assenza di vincoli di subordinazione nei confronti di altri soggetti.
    <br><br>
    Nel caso in cui uno o più Lavoratori autonomi collaborassero tra loro o con le imprese esecutrici all’esecuzione del medesimo incarico si configurerebbe una situazione di “appalto non genuino” sanzionabile dagli organismi di vigilanza.
  </ng-container>
</mat-dialog-content>
